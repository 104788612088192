@import "@/styles/functions";
@import "@/styles/new-variables";

.navigationButton {
  position: absolute;
  top: 0;
  bottom: 0;
  width: toRem(48);
  height: 100%;
  z-index: 3;
  transition: all 300ms;
  opacity: 0;
  background-color: transparent;

  &.left {
    left: -4%;
    // background: linear-gradient(270deg, rgba(15, 15, 18, 0) 0%, rgba(15, 15, 18, 0.6) 49.91%, #0f0f12 100%);
  }

  &.right {
    right: -4%;
    // background: linear-gradient(90deg, rgba(15, 15, 18, 0) 0%, rgba(15, 15, 18, 0.6) 49.91%, #0f0f12 100%);
  }

  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: toRem(44);
      height: toRem(44);
      cursor: pointer;
      border-radius: 50%;
      background: #ffffff38;
    }
  }

  &:disabled,
  &[disabled] {
    background: transparent;
    pointer-events: none;
    user-select: none;
    visibility: hidden;
    opacity: 0;
  }

  &.visible {
    opacity: 1;
  }
}

@media (min-width: get-breakpoint-width("xxl")) {
  .navigationButton {
    &.left {
      left: -3%;
    }

    &.right {
      right: -3%;
    }
  }
}
