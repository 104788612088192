@import '@/styles/new-variables';

.cardWrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: $black;

  .imageWrapper {
    border-radius: 1rem;

    &::after {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background: linear-gradient(180deg, rgba(15, 15, 18, 0.1) 0%, #0f0f12 100%);
      opacity: 1;
      transition: opacity 0.3s;
    }
  }

  .trashbinIcon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: toRem(12);
    right: toRem(12);
    width: toRem(36);
    height: toRem(36);
    transition: color 0.2s ease-in-out;
    z-index: 2;

    &:hover {
      color: $red;
    }
  }

  .playBtn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    gap: 0.625rem;
    color: $transparent-white-70;
    z-index: 2;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 50%;
    background: $transparent-white-10;
    backdrop-filter: blur(15px);
  }

  .bage {
    position: absolute;
    left: 0;
    top: 0;
  }

  .cardOverlayWrapper {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: flex-end;
    border-radius: 1rem;

    .cardControlPanel {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .processBar {
        padding: 0;
        z-index: 5;

        span {
          color: $white;
          padding-right: toRem(8);
          font-size: toRem(12);
          font-style: normal;
          font-weight: 600;
          line-height: 1rem; /* 133.333% */
        }
      }
    }
  }

  .title {
    color: $white;
    padding-top: toRem(8);
    font-size: torem(14);
    font-style: normal;
    font-weight: 600;
    line-height: 1rem; /* 114.286% */
  }
}

.skeltonImage {
  position: relative;
  overflow: hidden;
  color: transparent;
  border-radius: 1rem;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (min-width: 1200px) {
  .cardWrapper {
    .imageWrapper {
      &::after {
        opacity: 0;
      }

      &:hover {
        &::after {
          opacity: 1;
        }
      }
    }
  }
}
