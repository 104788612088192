@import '@/styles/functions';
@import '@/styles/new-variables';

.list {
  height: calc(100vh - 144px);
  overflow: auto;
  display: grid;
  padding: 1rem toRem(20);
  gap: toRem(20);
  padding: 1rem toRem(20);
}
