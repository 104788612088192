@import '@/styles/functions';
@import '@/styles/new-variables';

.date {
  color: $grey-3;
  font-weight: 500;
  line-height: 130%;
  margin-bottom: toRem(12);
}

.notificationCard {
  position: relative;
  cursor: pointer;
  border-radius: 1rem;
  background: var(--transparrent-white-white-6, rgba(255, 255, 255, 0.06));
  padding: toRem(12);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: toRem(10);
  align-self: stretch;

  &.isRead {
    background: $transparent-yellow-10;
  }

  .imageContainer {
    position: relative;
    overflow: hidden;

    .time {
      position: absolute;
      bottom: toRem(8);
      left: toRem(8);
      color: $transparent-white-50;
      font-size: toRem(12);
      line-height: 130%;
    }
  }

  .cardImage {
    width: 100%;
    border-radius: toRem(8);
    position: relative;
  }

  .title {
    color: $white;
    font-size: toRem(16);
    font-weight: 600;
    line-height: 130%;
    margin-bottom: toRem(4);
  }

  .description {
    color: $transparent-white-50;
    font-size: toRem(14);
    line-height: 130%;
  }
}
