@import '@/styles/variables';
@import '@/styles/new-variables';
@import '@/styles/functions';

.date {
  color: $grey-3;
  line-height: 130%;
  margin-bottom: toRem(12);
}

.notificationCard {
  position: relative;
  border-radius: 1rem;
  background: var(--transparrent-white-white-6, rgba(255, 255, 255, 0.06));
  padding: toRem(12);

  .imageContainer {
    min-width: toRem(341);
    border-radius: toRem(8);
  }

  .cardBody {
    margin-top: toRem(10);

    .title {
      color: $white;
      line-height: toRem(19.19);
      margin-bottom: toRem(4);
    }

    .description {
      color: $transparent-white-50;
      font-size: toRem(14);
      line-height: 130%;
    }
  }
}
