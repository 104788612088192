@import '@/styles/functions';
@import '@/styles/new-variables';

@font-face {
  font-family: 'Montserrat';
  src:
    url('/assets/fonts/Montserrat-Black.ttf') format('ttf'),
    url('/assets/fonts/Montserrat-Black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@mixin gradientColor {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

$image-radius: calc(1rem - 2px);

.topMovieCard {
  position: relative;
  height: 100%;
  border-radius: 1rem;
  background: $black;
  backdrop-filter: blur(15px);
  border: 2px solid $transparent-grey-10;

  .ratingCountWrapper {
    position: absolute;
    left: toRem(-10);
    top: 0;
    z-index: 2;

    @media (min-width: get-breakpoint-width('lg')) {
      left: toRem(-20);
    }

    .rating {
      font-family: Montserrat;
      font-size: toRem(92);
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      text-shadow:
        -1px -1px 0 rgb(118, 129, 119),
        1px -1px 0 rgb(118, 129, 119),
        -1px 1px 0 rgb(118, 129, 119),
        1px 1px 0 rgb(118, 129, 119);
      color: $white;

      &::before {
        content: attr(data-text);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-shadow: 16px 8px 30px rgba(15, 15, 18, 0.5);
        background: linear-gradient(180deg, rgba(117, 117, 117, 0.2) 0%, rgba(67, 72, 75) 100%);

        @include gradientColor();
      }

      &.first {
        text-shadow:
          -1px -1px 0 rgb(244, 198, 11),
          1px -1px 0 rgb(244, 198, 11),
          -1px 1px 0 rgb(244, 198, 11),
          1px 1px 0 rgb(244, 198, 11);

        &::before {
          background: linear-gradient(180deg, rgb(244, 198, 11) 30%, rgb(108, 125, 40) 100%);
          @include gradientColor();
        }
      }

      &.second {
        text-shadow:
          -1px -1px 0 rgb(209, 212, 216),
          1px -1px 0 rgb(209, 212, 216),
          -1px 1px 0 rgb(209, 212, 216),
          1px 1px 0 rgb(209, 212, 216);

        &::before {
          background: linear-gradient(180deg, rgb(209, 212, 216) 0%, rgb(159, 161, 166) 100%);
          @include gradientColor();
        }
      }

      &.third {
        text-shadow:
          -1px -1px 0 rgb(203, 115, 4),
          1px -1px 0 rgb(203, 115, 4),
          -1px 1px 0 rgb(203, 115, 4),
          1px 1px 0 rgb(203, 115, 4);

        &::before {
          background: linear-gradient(180deg, rgb(203, 115, 4) 0%, rgb(122, 41, 8) 100%);
          @include gradientColor();
        }
      }
    }
  }

  .movieImage {
    border-radius: $image-radius;
  }

  .movieCardBody {
    padding: toRem(12);
  }

  .topMovieCardOverlay {
    background: linear-gradient(
      180deg,
      rgba(15, 15, 18, 0.3) 0%,
      rgba(15, 15, 18, 0.8) 79.54%,
      #0f0f12 100%
    );
    border-radius: $image-radius;
  }
}
